import React, { useState } from 'react';
import { Typography} from 'antd';
import Boy from '../../assets/icons/Boy02.svg';
import {getGradeByScore, getGradeColor} from '../../constants';
import {Classschool, School, Statistic, Teacher} from '../../graphql/API';
import AddHomeworkModal from '../../components/AddHomeworkModal/AddHomeworkModal';

const {Text} = Typography;

type ReportHeaderProps = {
  teacher?: Teacher,
  school: School;
  selectedClass: Classschool;
  report: Statistic;
};

export const ReportHeader: React.FC<ReportHeaderProps> = ({
  teacher,
  school,
  selectedClass,
  report,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => setIsModalVisible(true);
  const hideModal = () => setIsModalVisible(false);
  return (
    <div className="container-fluid d-flex mx-0 px-0">
      <div className="row w-100 border-bottom mx-auto">

      <div className={`col-12 col-md-6 col-xl-4 row justify-content-center align-items-center me-2 px-0 ${window.innerWidth >= 992 ? 'border-end' : 'border-0 border-bottom'}`}>
        <div className="col-4  " style={{
            aspectRatio: '1 / 1',
            backgroundImage: `url(${teacher? teacher?.profilePicture?.thumbnailUri :Boy})`,
            backgroundSize: "cover",
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',  
          }}>
        </div>
        <div className="col-8">
          <Text
            style={{
              display: 'block',
              fontFamily: 'Arial Rounded MT Bold',
              fontSize: 21,
              fontWeight: "bold",
            }}>
            {teacher? "Mr. " + teacher?.name :selectedClass?.name}
          </Text>
          <Text style={{display: 'block', fontFamily: 'Arial Rounded MT Bold',  color: '#999999',}}>
        {teacher && school ? school.name : `Students in Class ${selectedClass.students.length}`}
          </Text>
        </div>
      </div>
      <div className={`col-12 col-md-6 col-xl-4 row justify-content-center align-items-center me-2 px-0 ${window.innerWidth >= 992 ? 'border-end' : 'border-0  border-bottom'}`}>
        <div
        className='col-4'
          style={{
            backgroundColor: getGradeColor(
              selectedClass.statistic?.averageScore
                ? selectedClass.statistic?.averageScore
                : 0,
                
            ),
            position: 'relative',
            height: '100%', 
            aspectRatio: '1 / 1',
          }}>
          <p
            style={{
              color: '#FFFFFF',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              fontSize: 25,
              fontFamily: 'Arial Rounded MT Bold',
            }}>
            {getGradeByScore(
              selectedClass.statistic?.averageScore
                ? selectedClass.statistic?.averageScore
                : 0,
            )}
          </p>
        </div>
        <div className='col-8' >
          <Text
            style={{
              fontFamily: 'Arial Rounded MT Bold',
              display: 'block',
              fontSize: 21,
              color: '#999999',
            }}>
            Total performance
          </Text>
          <Text
            style={{
              fontFamily: 'Arial Rounded MT Bold',
              fontSize: 27,
              color: '#40B2F2',
            }}>
            {report?.averageScore}%
          </Text>
        </div>
      </div>
      <div className='col-12  col-xl-4  row justify-content-center align-items-center  p-5 px-3 '>
       <button className='btn w-75 mx-auto btn-lg btn-info text-white rounded-0 fw-bold' onClick={showModal}>Add Homework</button>
      <AddHomeworkModal teacher={teacher} classSchool={selectedClass.id} visible={isModalVisible} onClose={hideModal}/>
      </div>
      </div>
    </div>
  );
};
