import {Row} from 'antd';
import Title from 'antd/es/typography/Title';
import {useSelector} from 'react-redux';

import TabsHeader from '../../components/Tabs';
import {reducersState} from '../../redux/reducers';

export const Home = () => {
  const currentUser = useSelector(
    (state: reducersState) => state.user.currentUser,
  );

  const partnerUser = useSelector(
    (state: reducersState) => state.user.partnerUser,
  );

  console.log('partnerUser,', partnerUser);
  console.log('currentUser2,', currentUser);

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        backgroundColor: 'white',
        // padding: 20,
        flexDirection: 'column',
      }}>
      {/* <div
        style={{
          marginInline: 20,
          flex: 1,
        }}>
        {currentUser?.teacher?.name && (
          <p
            style={{
              fontFamily: 'sans-serif',
              fontWeight: 'bold',
              fontSize: 15,
              color: 'salmon',
            }}>
            Hello, {currentUser?.teacher?.name}
          </p>
        )}
      </div> */}
      {/* <Row> */}
        <TabsHeader />
      {/* </Row> */}
    </div>
  );
};
