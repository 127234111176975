import React, {useState} from 'react';
import Swal from 'sweetalert2';
import styles from './AddHomeworkModal.module.css';
import {createClassHomework} from '../../redux/actions/homework';
import {useDispatch} from 'react-redux';
import {Teacher} from '../../graphql/API';

interface AddHomeworkModalProps {
  visible: boolean;
  onClose: () => void;
  classSchool: string;
  teacher: Teacher;
}

const AddHomeworkModal: React.FC<AddHomeworkModalProps> = ({
  visible,
  onClose,
  teacher,
  classSchool,
}) => {
  const dispatch = useDispatch();
  const [isHomework, setIsHomework] = useState('');
  if (!visible) return null;
  const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  function cleanStringForAI(input) {
    return input
      .toLowerCase() // Convert to lowercase
      .replace(/[^a-z0-9'\s]/g, ''); // Remove special characters and punctuation, except apostrophe
  }

  const handleSubmit = () => {
    const aiText = cleanStringForAI(isHomework);
    if (isHomework.length == 0) {
      Swal.fire({
        icon: 'error',
        title: 'Homework cannot be empty',
        text: 'please write a paragraph for reading to be recorded by students with AI',
      });
    } else {
      //@ts-ignore
      (
        dispatch(
          createClassHomework({
            text: isHomework,
            aiText,
            classschoolId: classSchool,
            teacherId: teacher.id,
            teacherName: teacher.name,
          }),
        ) as unknown as Promise<boolean>
      ).then((response: any) => {
        if (response) {
          Swal.fire({
            icon: 'success',
            title: `Homework ${isHomework} sent to class `,
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error creating homework',
          });
        }
      });
      onClose();
    }
  };

  const handleCloseClick = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#40b2f2',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        onClose();
      }
    });
  };

  return (
    <div onClick={handleOverlayClick} className={styles.modalOverlay}>
      <div
        className={`d-flex justify-content-center px-4 align-items-center flex-column rounded ${styles.modalCard}`}>
        <div className="d-flex justify-content-between w-100">
          <h2 className="text-start w-100 h4">Add homework</h2>
          <button
            onClick={handleCloseClick}
            className="border-0 bg-transparent">
            x
          </button>
        </div>
        <label htmlFor="hoemworkText" className="mb-2 text-start w-100">
          Add paragraph for reading to be recorded by students with AI
        </label>
        {/* <input type="text" /> */}
        <textarea
          name="hoemworkText"
          id="hoemworkText"
          cols={100}
          rows={10}
          maxLength={250}
          onChange={(e) => setIsHomework(e?.target?.value)}
          onBlur={(e) => setIsHomework(e?.target?.value)}
          className={`border-0  p-4 h6 mb-1 ${styles.homeworkParagraph}`}></textarea>
        <p className="d-flex justify-content-end w-100  mb-2">
          Maximum 250 characters to be recorded in 30 seconds
        </p>
        <div className="d-flex w-100 justify-content-end align-items-center">
          <button
            onClick={handleSubmit}
            className="btn bg-pikado-blue rounded-0 px-4">
            Send Homework
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddHomeworkModal;
