import {Question} from '../../graphql/API';
import {QuestionsRepository} from '../repositories/QuestionsRepository';
import {IQuestionsService} from './interfaces/IQuestionsService';

export class QuestionsService implements IQuestionsService {
  questionsRepository: QuestionsRepository;
  constructor() {
    this.questionsRepository = new QuestionsRepository();
  }

  async createQuestion(question: Question): Promise<any> {
    let questionObj = {
      name: question.name,
      summary: question.summary,
      description: question.description,
      text: question.text,
      correctAssetId: '',
      assetId: '',
      programType: question.programType,
      choices: question.choices?.length !== 0 ? question.choices : [],
      customChoices:
        question?.customChoices &&
        Object.keys(question?.customChoices).length > 0
          ? JSON.stringify(question.customChoices)
          : undefined,
      keywords: [],
      type: question.type,
      textAI: question.textAI,
    };
    return await this.questionsRepository.createQuestion(questionObj);
  }
}
