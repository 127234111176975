import {ProgramType, QUESTION_TYPE, Question} from '../../graphql/API';
import {HomeworkService} from '../../lib/services/HomeworkService';
import {QuestionsService} from '../../lib/services/QuestionsService';

const questionService = new QuestionsService();
const homeworkService = new HomeworkService();
export const createClassHomework =
  (q: {
    text: string;
    aiText: string;
    classschoolId?: string;
    teacherId?: string;
    teacherName?: string;
  }) =>
  async (dispatch: any, getState: any) => {
    try {
      let question: string = await questionService.createQuestion({
        choices: [],
        description: 'Please read the following paragraph with your own voice.',
        keywords: [],
        name: `Homework-${q.teacherName.slice(0, 5)}-${new Date()}`,
        programType: ProgramType.LANGUAGE,
        summary: 'Record with your own voice',
        text: q.text,
        textAI: q.aiText,
        type: QUESTION_TYPE.READ_SENTENCE,
        __typename: 'Question',
        id: '',
        createdAt: '',
        updatedAt: '',
      });

      if (question) {
        let newHomework = await homeworkService.createHomework({
          classschoolId: q.classschoolId,
          questionId: question,
          teacherId: q.teacherId,
        });
        if (newHomework) return true;
      } else {
        console.log('ERROR_creating_question');
        return false;
      }
    } catch (error) {
      console.log('ERROR_createClassHomework', error);
      return false;
    }
  };
