import {HomeworkInsert} from '../../graphql/API';
import {HomeworkRepository} from '../repositories/HomeworkRepository';
import {IHomeworkService} from './interfaces/IHomeworkService';

export class HomeworkService implements IHomeworkService {
  homeworkRepository: HomeworkRepository;
  constructor() {
    this.homeworkRepository = new HomeworkRepository();
  }

  async createHomework(homework: HomeworkInsert): Promise<any> {
    const currentDate = new Date();
    const futureDate = new Date(currentDate);
    futureDate.setDate(futureDate.getDate() + 2);
    const futureDateString = futureDate.toISOString();
    let homeworkObj = {
      classschoolId: homework.classschoolId,
      expiryDate: futureDateString,
      isActive: true,
      questionId: homework.questionId,
      teacherId: homework.teacherId,
    };
    return await this.homeworkRepository.createHomework(homeworkObj);
  }
}
