import {Divider, Image, List, notification, Progress, Typography} from 'antd';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';

import AttendanceCircle from '../../assets/icons/AttendanceCircle.svg';
import Boy from '../../assets/icons/Boy02.svg';
import './reportPage.css';

import {useHistory} from 'react-router-dom';
import {Loading} from '../../components';
import {
  Classschool,
  MonthlyReport,
  Statistic,
  Student,
  User,
} from '../../graphql/API';
import {setSelectedStudent} from '../../redux/actions/student';
import {reducersState} from '../../redux/reducers';
import {DemoLine} from './Graph';
import {StatisticRepository} from './StatisticRepository';
import {getGradeByScore, getGradeColor} from '../../constants';
import {ReportHeader} from './ReportHeader';

const statisticRepository = new StatisticRepository();
const EMPTY_STATISTIC = {
  id: '1234b841-5ce1-424a-8c4e-b0783341234',
  createdAt: '2024-10-19T21:37:16.114Z',
  updatedAt: '2024-10-19T21:37:16.114Z',
  scores: null,
  averageScore: 0,
  fullReportLastGeneratedDate: null,
  phoneticsAsString: null,
  syllablesAsString: null,
  monthlyReports: [],
};

type ReportPageType = {
  classId: string;
  selectedMonth: string;
  monthIndex: number;
};
export const ReportPage = ({
  classId,
  selectedMonth,
  monthIndex,
}: ReportPageType) => {
  const dispatch = useDispatch();
  const {Text} = Typography;
  //@ts-ignore
  const user: User = useSelector((state: any) => state.user.user);
  const history = useHistory();
  const school = useSelector(
    (state: reducersState) =>
      state.user?.currentUser?.school ??
      state.user?.currentUser?.teacher?.school,
  );

  const currentUserteacher = useSelector(
    (state: reducersState) => state.user?.currentUser?.teacher,
  );

  console.log('school', school);

  const [report, setReport] = useState<Statistic>();
  const [currentMonthlyReport, setCurrentMonthlyReport] =
    useState<MonthlyReport>();
  const [allMonthyReports, setAllMonthyReports] = useState<MonthlyReport[]>();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [sortedStudents, setSortedStudents] = useState<Array<Student>>();

  const [performanceData, setPerformanceData] = useState<any>([]);
  const [performaceDailyGraph, setPerformaceDailyGraph] = useState<any>([]);

  const [sortedDaysList, setSortedDaysList] = useState<any>();
  const [selectedClass, setSelectedClass] = useState<Classschool>();

  useEffect(() => {
    console.log('inside regen');
    console.log('will send generateFullReport');
    let selected =
      currentUserteacher?.classesschool?.find((sc) => sc.id === classId) ||
      school?.classesschool?.find((sc) => sc.id === classId);

    if (selected) {
      setSelectedClass(selected);
    }

    let classSortedStudents = selected.students.sort((a, b) => {
      const averageScoreA = a.statistic?.averageScore ?? 0;
      const averageScoreB = b.statistic?.averageScore ?? 0;
      return averageScoreB - averageScoreA;
    });
    setSortedStudents(classSortedStudents);
    console.log('Class ID', classId);

    statisticRepository
      .generateClassFullReport(classId)
      .then((generateClassFullReportResponse: string) => {
        let classStatisticId = selected?.statistic?.id;
        try {
          let parsedResponse: {
            message: string;
            classStatisticId: string;
          } = JSON.parse(generateClassFullReportResponse);
          if (!classStatisticId) {
            classStatisticId = parsedResponse.classStatisticId;
          }
        } catch (e) {}
        statisticRepository
          .getFullReport(classStatisticId)
          .then((fullReport) => {
            let sortedMonthlyReports: MonthlyReport[] =
              fullReport?.monthlyReports.sort((a: any, b: any) => {
                //@ts-ignore
                return new Date(a.month) - new Date(b.month);
              });

            console.log('sortedMonthlyReports', sortedMonthlyReports);

            let performaceGraph: {Score: number; Month: string}[] = [];
            let count = 0;

            while (count < sortedMonthlyReports.length && count < 6) {
              const mr = sortedMonthlyReports[count];
              performaceGraph?.push({
                Score: mr?.average ? mr.average : 0,
                Month: moment(mr.month).format('MMM YY'),
              });
              count++;
            }

            console.log('fullReport.monthlyReports', fullReport.monthlyReports);
            console.log('performaceGraph', performaceGraph);

            const parsedMonthlyReports = fullReport.monthlyReports?.map(
              (rep: any) => {
                console.log('HELLO_MAONG', rep);
                let monthlyReportInput: any = {...rep};
                console.log('HELLO_MAONG0000', monthlyReportInput['d1']);

                for (let dayIndex = 1; dayIndex <= 31; dayIndex++) {
                  console.log(
                    'HELLO_MAONG222222',
                    monthlyReportInput[`d${dayIndex}`],
                  );
                  console.log('HELLO_MAONG33333', rep[`d${dayIndex}`]);

                  monthlyReportInput[`d${dayIndex}`] = JSON.parse(
                    rep[`d${dayIndex}`],
                  );
                }

                return monthlyReportInput;
              },
            );

            console.log('parsedMonthlyReports', parsedMonthlyReports);

            let currentMonthReport: MonthlyReport = parsedMonthlyReports?.find(
              (mr: MonthlyReport) =>
                moment(mr.month).format('YYYY-MM') === selectedMonth,
            );

            let performaceDailyGr: {Score: number; Month: string}[] = [];

            const isCurrentReportOld = moment(
              currentMonthReport?.month,
            ).isBefore(moment(), 'M');
            console.log('isCurrentReportOld', isCurrentReportOld);
            const endOfloop = isCurrentReportOld
              ? moment(currentMonthReport?.month)
                  .clone()
                  .endOf('month')
                  .date()
              : moment().date() - 1;

            for (let i = 1; i <= endOfloop; i++) {
              if (currentMonthReport) {
                console.log('DAYYYYY', currentMonthReport['d' + i]);

                performaceDailyGr?.push({
                  Score: currentMonthReport['d' + i]?.average
                    ? parseInt(currentMonthReport['d' + i].average)
                    : 0,
                  Month: moment(currentMonthReport?.month)
                    .set('date', i)
                    .format('ddd-DD'),
                });
              }
            }
            console.log('performaceDailyGr', performaceDailyGr);

            try {
              fullReport.phoneticsAsString = JSON.parse(
                fullReport.phoneticsAsString,
              );
            } catch (e) {
              console.log('Inside parsing catch->', e);
            }

            try {
              fullReport.syllablesAsString = JSON.parse(
                fullReport.syllablesAsString,
              );
            } catch (e) {
              console.log('Inside parsing catch->', e);
            }

            setPerformaceDailyGraph(performaceDailyGr);
            setPerformanceData(performaceGraph);
            setCurrentMonthlyReport(currentMonthReport);
            setAllMonthyReports(sortedMonthlyReports);

            setReport(fullReport);
            getSortedDaysList();
            setIsLoading(false);
          })
          .catch((error) => {
            // @ts-ignore
            setReport(EMPTY_STATISTIC);
            console.log('Error in generateClassFullReport: ', error);
            alert(`No report was found.`);
            setIsLoading(false);
          });
      })
      .catch((e) => {
        console.log('Error generating the class report', e);
      });
  }, []);

  // useEffect(() => {
  //   let currentMonthReport: MonthlyReport = allMonthyReports?.find(
  //     (mr: MonthlyReport) =>
  //       moment(mr.month).format('YYYY-MM') === selectedMonth,
  //   );

  //   let performaceDailyGr: {Score: number; Month: string}[] = [];

  //   const isCurrentReportOld = moment(currentMonthReport?.month).isBefore(
  //     moment(),
  //     'M',
  //   );
  //   console.log('isCurrentReportOld', isCurrentReportOld);
  //   const endOfloop = isCurrentReportOld
  //     ? moment(currentMonthReport?.month).clone().endOf('month').date()
  //     : moment().date() - 1;

  //   for (let i = 1; i <= endOfloop; i++) {
  //     if (currentMonthReport) {
  //       console.log('DAYYYYY', currentMonthReport['d' + i]);

  //       performaceDailyGr?.push({
  //         Score: currentMonthReport['d' + i]?.average
  //           ? parseInt(currentMonthReport['d' + i].average)
  //           : 0,
  //         Month: moment(currentMonthReport?.month)
  //           .set('date', i)
  //           .format('ddd-DD'),
  //       });
  //     }
  //   }
  //   console.log('performaceDailyGr', performaceDailyGr);

  //   setPerformaceDailyGraph(performaceDailyGr);
  // }, [allMonthyReports, selectedMonth]);

  useEffect(() => {
    getSortedDaysList();
  }, [currentMonthlyReport]);
  const getSortedDaysList = () => {
    let list = {};
    const isCurrentReportOld = moment(currentMonthlyReport?.month).isBefore(
      moment(),
      'M',
    );
    console.log('isCurrentReportOld', isCurrentReportOld);
    const endOfloop = isCurrentReportOld
      ? moment(currentMonthlyReport?.month)
          .clone()
          .endOf('month')
          .date()
      : moment().date();

    for (let i = 1; i <= endOfloop; i++) {
      if (currentMonthlyReport) {
        //@ts-ignore
        list['d' + i] = currentMonthlyReport['d' + i];
      }
    }

    console.log('sorted->', list);
    setSortedDaysList(list);
  };

  // const getAbsenceDays = () => {
  //   let absenceDays = 0;
  //   for (let i = 1; i <= 31; i++) {
  //     //@ts-ignore
  //     if (
  //       currentMonthlyReport &&
  //       (currentMonthlyReport[`d${i}`] === null ||
  //         currentMonthlyReport[`d${i}`] === 0)
  //     ) {
  //       absenceDays = absenceDays + 1;
  //     }
  //   }
  //   return absenceDays;
  // };

  const attendanceGraph = () => {
    return Object.entries(sortedDaysList).map(function ([key, value], index) {
      return (
        <div
          style={{
            marginBottom: 10,
            alignSelf: 'self-end',
          }}>
          <div
            style={{
              borderBottom: '1px solid #999999',
              paddingBottom: 10,
              display: 'flex',
              flexDirection: 'column-reverse',
              alignItems: 'center',
            }}>
            {[...new Array(sortedDaysList[key].numOfTotalAns)].map(
              (_, index) => {
                return index < 5 ? (
                  <Image
                    wrapperStyle={{display: 'block'}}
                    width={8}
                    preview={false}
                    src={AttendanceCircle}
                  />
                ) : (
                  index === 5 && (
                    <Text
                      style={{
                        fontFamily: 'Arial Rounded MT Bold',
                        color: '#999999',
                        fontSize: 13,
                      }}>
                      +{sortedDaysList[key].numOfTotalAns - 5}
                    </Text>
                  )
                );
              },
            )}
          </div>

          <Text className="attendance-home-graph-index">{index + 1}</Text>
        </div>
      );
    });
  };

  const renderMonthlyAttendance = () => {
    return (
      <div className="attendanceGraph">
        <div style={{marginBottom: 30}}>
          <Text
            style={{
              display: 'inline-block',
              fontFamily: 'Arial Rounded MT Bold',
              color: '#999999',
              fontSize: 18,
              textDecoration: 'underline',
            }}>
            Attendance This Month {selectedMonth}
          </Text>
          <div style={{display: 'inline-block', float: 'right'}}>
            <Text
              style={{
                fontFamily: 'Arial Rounded MT Bold',
                color: '#C3E56E',
                marginRight: 20,
                fontSize: 20,
              }}>
              {currentMonthlyReport?.attempts !== 0
                ? `${currentMonthlyReport?.attempts ?? 0} Recorded Exercises`
                : 'No Recorded Exercises for this month yet.'}
            </Text>
            <Text
              style={{
                fontFamily: 'Arial Rounded MT Bold',
                color: '#F2406A',
                fontSize: 20,
              }}></Text>
          </div>
        </div>
        <div
          style={{
            display: 'grid',
            gridAutoFlow: 'column',
            //@ts-ignore
            textAlign: '-webkit-center',
            minHeight: 200,
          }}>
          {attendanceGraph()}
        </div>
      </div>
    );
  };

  const RenderDailyPerformance = () => {
    return (
      <div
        className="renderDailyPerformance-grid-columns"
        style={{
          display: 'grid',
          marginBottom: 60,
        }}>
        <div>
          <Text
            style={{
              display: 'block',
              fontFamily: 'Arial Rounded MT Bold',
              color: '#999999',
              fontSize: 18,
              marginBottom: 30,
              marginTop: 30,
              textDecoration: 'underline',
            }}>
            Daily Performance
          </Text>
          {/*@ts-ignore*/}
          <DemoLine data={performaceDailyGraph} />
        </div>
      </div>
    );
  };
  const renderPerformance = () => {
    return (
      <div
        className="renderPerformance-grid-columns"
        style={{
          display: 'grid',
          marginBottom: 60,
        }}>
        <div>
          <Text
            style={{
              display: 'block',
              fontFamily: 'Arial Rounded MT Bold',
              color: '#999999',
              fontSize: 18,
              marginBottom: 30,
              marginTop: 30,
              textDecoration: 'underline',
            }}>
            Current Month Performance
          </Text>
          <Progress
            width={300}
            format={(percent) => (
              <div>
                <Text
                  style={{
                    display: 'block',
                    marginTop: 10,
                    fontFamily: 'Arial Rounded MT Bold',
                    color: '#40B2F2',
                    fontSize: 40,
                  }}>
                  {currentMonthlyReport?.average ?? 0}%
                </Text>

                <Text
                  style={{
                    display: 'block',
                    fontFamily: 'Arial Rounded MT Bold',
                    color: '#000000',
                    fontSize: 13,
                    fontWeight: 'bold',
                  }}>
                  Native American Accent
                </Text>
                <Text
                  style={{
                    display: 'block',
                    marginTop: 10,
                    fontFamily: 'Arial Rounded MT Bold',
                    color: '#999999',
                    fontSize: 13,
                  }}>
                  {moment().format('MMMM')}
                </Text>
              </div>
            )}
            type="circle"
            percent={
              currentMonthlyReport?.average ? currentMonthlyReport?.average : 0
            }
            strokeWidth={3}
            strokeColor={{'0%': '#6EBEF2', '100%': '#C3E56F'}}
          />
        </div>
        <Divider
          orientation={'center'}
          style={{height: '100%', justifySelf: 'center'}}
          type={'vertical'}
        />
        <div>
          <Text
            style={{
              display: 'block',
              fontFamily: 'Arial Rounded MT Bold',
              color: '#999999',
              fontSize: 18,
              marginBottom: 30,
              marginTop: 30,
              textDecoration: 'underline',
            }}>
            Last 6 Months Performance
          </Text>
          {/*@ts-ignore*/}
          <DemoLine data={performanceData} />
        </div>
      </div>
    );
  };

  const renderMistakes = () => {
    // @ts-ignore
    return (
      <div
        className="renderMistakes-columns"
        style={{
          display: 'grid',
          paddingBottom: 30,
        }}>
        <div>
          <Text
            style={{
              display: 'block',
              fontFamily: 'Arial Rounded MT Bold',
              color: '#999999',
              fontSize: 18,
              marginBottom: 30,
              marginTop: 30,
              textDecoration: 'underline',
            }}>
            Syllables Mistakes
          </Text>

          <List
            className={'scrollable-element'}
            style={{overflow: 'auto', height: 500}}
            dataSource={
              //@ts-ignore
              report?.syllablesAsString
                ? //@ts-ignore
                  report?.syllablesAsString?.sort(
                    //@ts-ignore
                    (a: Syllable, b: Syllable) => b.mistakes - a.mistakes,
                  )
                : []
            }
            split={false}
            renderItem={(item, index) => (
              <List.Item
                //@ts-ignore
                key={`${item?.letters}+${index}`}
                style={{paddingLeft: 0}}>
                <div style={{display: 'grid', gridAutoFlow: 'column'}}>
                  <div style={{width: 100}}>
                    <Text
                      style={{
                        display: 'block',
                        fontFamily: 'Arial Rounded MT Bold',
                        color: '#40B2F2',
                        fontSize: 18,
                        marginRight: 50,
                      }}>
                      {/*@ts-ignore*/}
                      {item?.letters}
                    </Text>
                  </div>
                  <div style={{}}>
                    <Text
                      style={{
                        display: 'block',
                        fontFamily: 'Arial Rounded MT Bold',
                        color: '#000000',
                        fontSize: 18,
                      }}>
                      {/*@ts-ignore*/}
                      {item?.mistakes} Mistake
                    </Text>
                    <Text
                      style={{
                        display: 'block',
                        fontFamily: 'Arial Rounded MT Bold',
                        color: '#999999',
                        fontSize: 18,
                      }}>
                      {/*@ts-ignore*/}
                      {item?.attempts} Trails
                    </Text>
                  </div>
                </div>
              </List.Item>
            )}
          />
        </div>
        <Divider
          orientation={'center'}
          style={{height: '50%', justifySelf: 'center', alignSelf: 'center'}}
          type={'vertical'}
        />
        <div>
          <Text
            style={{
              display: 'block',
              fontFamily: 'Arial Rounded MT Bold',
              color: '#999999',
              fontSize: 18,
              marginBottom: 30,
              marginTop: 30,
              textDecoration: 'underline',
            }}>
            Phonetics Mistakes
          </Text>
          <List
            className={'scrollable-element'}
            style={{overflow: 'auto', height: 500}}
            dataSource={
              //@ts-ignore
              report?.phoneticsAsString
                ? //@ts-ignore
                  report?.phoneticsAsString?.sort(
                    //@ts-ignore
                    (a: any, b: any) => b.mistakes - a.mistakes,
                  )
                : []
            }
            split={false}
            renderItem={(item, index) => (
              <List.Item
                //@ts-ignore
                key={`${item?.phone}+${index}`}
                style={{paddingLeft: 0}}>
                <div style={{display: 'grid', gridAutoFlow: 'column'}}>
                  <div style={{width: 100}}>
                    <Text
                      style={{
                        display: 'block',
                        fontFamily: 'Arial Rounded MT Bold',
                        color: '#F2406A',
                        fontSize: 18,
                        marginRight: 50,
                      }}>
                      {/*@ts-ignore*/}
                      {item?.phone}
                    </Text>
                  </div>
                  <div>
                    <Text
                      style={{
                        display: 'block',
                        fontFamily: 'Arial Rounded MT Bold',
                        color: '#000000',
                        fontSize: 18,
                      }}>
                      {/*@ts-ignore*/}
                      {item?.mistakes} Mistake
                    </Text>
                    <Text
                      style={{
                        display: 'block',
                        fontFamily: 'Arial Rounded MT Bold',
                        color: '#999999',
                        fontSize: 18,
                      }}>
                      {/*@ts-ignore*/}
                      {item?.attempts} Trails
                    </Text>
                  </div>
                </div>
              </List.Item>
            )}
          />
        </div>
        <Divider
          orientation={'center'}
          style={{height: '50%', justifySelf: 'center', alignSelf: 'center'}}
          type={'vertical'}
        />
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <Text
              style={{
                display: 'block',
                fontFamily: 'Arial Rounded MT Bold',
                color: '#999999',
                fontSize: 18,
                marginBottom: 30,
                marginTop: 30,
                textDecoration: 'underline',
              }}>
              Students arrangement
            </Text>
            <Text
              onClick={() => {
                history.push({
                  pathname: '/students-arrangement',
                  state: {
                    classId: classId,
                    schoolId: school.id,
                    school: school,
                    selectedClass: selectedClass,
                    report: report,
                  },
                });
              }}
              style={{
                backgroundColor: '#000',
                color: '#FFFFFF',
                fontFamily: 'Arial Rounded MT Bold',
                border: 'none',
                borderRadius: 5,
                padding: '10px 20px',
                fontSize: 18,
                cursor: 'pointer',
              }}>
              See All
            </Text>
          </div>

          <List
            className={'scrollable-element'}
            style={{
              overflow: 'auto',
              height: 500,
              cursor: 'pointer',
            }}
            dataSource={sortedStudents}
            split={true}
            renderItem={(item, index) => (
              <List.Item
                onClick={() => {
                  console.log('item', item);
                  dispatch(setSelectedStudent(item));
                  if (item.statistic) {
                    history.push(`/home/student/${item.id}`);
                  } else {
                    notification.warning({
                      placement: 'top',
                      duration: 3,
                      message: 'No Report for this student yet',
                    });
                  }
                }}
                key={`${item}+${index}`}
                style={{paddingLeft: 0}}>
                <div
                  style={{
                    display: 'grid',
                    gridAutoFlow: 'column',
                    alignItems: 'center',
                  }}>
                  <div className="student-row">
                    <div
                      style={{
                        backgroundColor: '#F2406A',
                        position: 'relative',
                        width: 50,
                        height: 50,
                        borderRadius: 50,
                      }}>
                      <Text
                        style={{
                          color: '#FFFFFF',
                          fontSize: 14,
                          fontFamily: 'Arial Rounded MT Bold',
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                        }}>
                        {index + 1}
                      </Text>
                    </div>
                    <Image
                      width={65}
                      preview={false}
                      src={Boy}
                      style={{display: 'inline-block'}}
                      wrapperStyle={{verticalAlign: 'top', padding: 10}}
                    />
                  </div>
                  <div className="studentTextContainer">
                    <Text
                      style={{
                        display: 'block',
                        fontFamily: 'Arial Rounded MT Bold',
                        color: '#000000',
                        fontSize: 18,
                      }}>
                      {item.name?.length < 20
                        ? item.name
                        : item.name.substring(0, 18) + '...'}
                    </Text>
                    <Text
                      style={{
                        display: 'block',
                        fontFamily: 'Arial Rounded MT Bold',
                        color: '#000000',
                        fontSize: 14,
                      }}>
                      {'Total Perf. '}
                      {item.statistic?.averageScore
                        ? item.statistic?.averageScore
                        : 0}
                      %
                    </Text>
                  </div>
                </div>
                <div
                  className="gradeContainer"
                  style={{
                    backgroundColor: getGradeColor(
                      item.statistic?.averageScore
                        ? item.statistic?.averageScore
                        : 0,
                    ),
                  }}>
                  <Text
                    style={{
                      color: '#FFFFFF',
                      fontSize: 18,
                      fontFamily: 'Arial Rounded MT Bold',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}>
                    {getGradeByScore(
                      item.statistic?.averageScore
                        ? item.statistic?.averageScore
                        : 0,
                    )}
                  </Text>
                </div>
              </List.Item>
            )}
          />
        </div>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div style={{textAlign: 'center'}}>
        <Text
          style={{
            display: 'block',
            fontFamily: 'Arial Rounded MT Bold',
            color: '#C4C4C4',
            fontSize: 13,
          }}>
          PikaDo LLC
        </Text>
        <Text
          style={{
            display: 'block',
            fontFamily: 'Arial Rounded MT Bold',
            color: '#C4C4C4',
            fontSize: 13,
          }}>
          Suite 302, 4001 Kennett Pike, County of New Castle, Wilmington,
          Delaware 19807, USA
        </Text>
        <div>
          <a
            style={{display: 'inline-block'}}
            target="_blank"
            href={'https://pikado.io/'}>
            <Text
              style={{
                display: 'inline-block',
                fontFamily: 'Arial Rounded MT Bold',
                color: '#C4C4C4',
                fontSize: 13,
                marginRight: 20,
              }}>
              www.PikaDo.io
            </Text>
          </a>
          <a
            style={{display: 'inline-block'}}
            target="_blank"
            href={'mailto:abc@example.com'}>
            <Text
              style={{
                display: 'inline-block',
                fontFamily: 'Arial Rounded MT Bold',
                color: '#C4C4C4',
                fontSize: 13,
              }}>
              info@PikaDo.io
            </Text>
          </a>
        </div>
      </div>
    );
  };
  const renderNoReportAvailable = () => {
    return (
      <div style={{textAlign: 'center'}}>
        <Text
          style={{
            display: 'block',
            fontFamily: 'Arial Rounded MT Bold',
            color: '#000000',
            fontSize: 25,
            marginBottom: 30,
            marginTop: 50,
          }}>
          There is no report available for this class yet.
        </Text>
      </div>
    );
  };
  return (
    <div className="container-fluid px-0">
      {report && (
        <>
          
          <ReportHeader
            teacher={currentUserteacher}
            school={school}
            selectedClass={selectedClass}
            report={report}
          />
{!report?.fullReportLastGeneratedDate &&
            !isLoading &&
            renderNoReportAvailable()}
          {renderMonthlyAttendance()}
          <Divider />
          {performaceDailyGraph.length > 0 && (
            <>
              <RenderDailyPerformance />
              <Divider />
            </>
          )}

          {renderPerformance()}
          <Divider />
          {renderMistakes()}
          {renderFooter()}
        </>
      )}
      {isLoading && (
        <Loading title={'Analysing your data. it may take a minute.'} />
      )}
    </div>
  );
};
