import React, {useEffect, useState} from 'react';
import {Modal, Progress, Typography} from 'antd';
import HumanAudioIcon from '../../../assets/icons/HumanAudioIcon.svg';
import AnswerComponent from './AnswerComponent';
import './reportPage.css';

interface Question {
  id: string;
  numOfAns: number;
  answersIds: string[];
}

interface infoProps {
  average: number;
  numOfTotalAns: number;
  numOfTotalQuestions: number;
  numOfUniqueTotalAns: number;
  questions: Question[];
  totalScores: number;
  day: number;
}

interface SimpleModalProps {
  isOpen: boolean;
  onClose: () => void;
  info: infoProps | null;
}

const AttendanceDashboardModal: React.FC<SimpleModalProps> = ({
  isOpen,
  onClose,
  info,
}) => {
  const [stopAudioPlayback, setStopAudioPlayback] = useState(false);
  const [currentPlayingId, setCurrentPlayingId] = useState<string | null>(null);

  const handleAudioPlay = (answerId: string) => {
    if (currentPlayingId !== answerId) {
      setCurrentPlayingId(answerId);
    }
  };

  const onCloseHandler = () => {
    setCurrentPlayingId(null);
    setStopAudioPlayback(true);
    onClose();
  };
  useEffect(() => {
    if (isOpen) {
      setStopAudioPlayback(false);
    }
  }, [isOpen]);

  if (!isOpen || !info) return null;

  const {Text} = Typography;

  const modalWidth = window.innerWidth < 768 ? '90%' : '30%';

  return (
    <Modal
      className="analysis-modal-wrapper"
      style={{ maxHeight: '90%', width: modalWidth }}
      // style={{maxHeight: '90%'}}
      // width={'30%'}
      centered={true}
      title={`Day ${info.day} Analysis`}
      open={isOpen}
      onOk={() => {}}
      footer={null}
      onCancel={() => {
        onCloseHandler();
      }}>
      <div
        className="modal-progress-container">
        <Progress
          size={100}
          format={() => {
            return (
              <div>
                <Text
                  style={{
                    display: 'block',
                    fontFamily: 'Arial Rounded MT Bold',
                    fontSize: 32,
                    color: '#40B2F2',
                  }}>
                  {info?.numOfTotalAns}
                </Text>
                <Text
                  style={{
                    display: 'block',
                    fontFamily: 'Arial Rounded MT Bold',
                    fontSize: 10,
                    lineHeight: '12px',
                  }}>
                  Recorded <br /> Answers
                </Text>
              </div>
            );
          }}
          type="circle"
          percent={100}
          strokeWidth={3}
          strokeColor={'#40B2F2'}
        />
        <Progress
          size={100}
          format={() => {
            return (
              <div>
                <Text
                  style={{
                    display: 'block',
                    fontFamily: 'Arial Rounded MT Bold',
                    fontSize: 32,
                    color: '#FFD252',
                  }}>
                  {info?.numOfTotalQuestions}
                </Text>
                <Text
                  style={{
                    display: 'block',
                    fontFamily: 'Arial Rounded MT Bold',
                    fontSize: 10,
                    lineHeight: '12px',
                  }}>
                  Unique <br /> Questions
                </Text>
              </div>
            );
          }}
          type="circle"
          percent={100}
          strokeWidth={3}
          strokeColor={'#FFD252'}
        />
        <Progress
          size={100}
          format={() => {
            return (
              <div>
                <Text
                  style={{
                    display: 'block',
                    fontFamily: 'Arial Rounded MT Bold',
                    fontSize: 24,
                    color: '#F2406A',
                  }}>
                  {Math.floor(info?.average)}%
                </Text>
                <Text
                  style={{
                    display: 'block',
                    fontFamily: 'Arial Rounded MT Bold',
                    fontSize: 9,
                    lineHeight: '12px',
                  }}>
                  Current Day <br /> Performance
                </Text>
              </div>
            );
          }}
          type="circle"
          percent={Math.floor(info?.average)}
          strokeWidth={3}
          strokeColor={{'0%': '#F2406A', '100%': '#FFD252'}}
        />
      </div>
      {info?.questions.map((question, index) => (
        <div className="modal-question-container" key={question.id}>
          <img
            style={{marginLeft: -10}}
            src={HumanAudioIcon}
            alt="human-audio-icon"
          />
          <Text
            style={{
              fontFamily: 'Arial Rounded MT Bold',
              textDecoration: 'underline',
              marginLeft: 5,
            }}>
            Question {index + 1} ({question.numOfAns} Answers)
          </Text>
          {question.answersIds.map((answerId, answerIndex) => (
            <AnswerComponent
              key={answerIndex}
              answerId={answerId}
              isPlaying={currentPlayingId === answerId}
              onPlay={() => handleAudioPlay(answerId)}
              index={answerIndex}
              stopAudioPlayback={stopAudioPlayback}
              currentPlayingId={currentPlayingId}
            />
          ))}
        </div>
      ))}
    </Modal>
  );
};

export default AttendanceDashboardModal;
