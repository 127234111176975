import {combineReducers} from 'redux';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import {RESET_USER} from '../actions/types';
import avaliablity, {availabilityState} from './availability';
import review, {ReviewState} from './review';
import user, {UserState} from './user';
import student from './student';

const userPersistConfig = {
  key: 'user',
  storage,
  whitelist: ['user', 'isLoggedIn', 'partnerUser'],
  blacklist: [
    'isLoadingRequest',
    'error',
    'conversations',
    'subscribedConversationIds',
  ],
};

const avaliabilityPersistConfig = {
  key: 'avaliablity',
  storage,
  whitelist: [],
};

const reviewPersistConfig = {
  key: 'review',
  storage,
  whitelist: [],
};

export type reducersState = {
  user: UserState;
  avaliablity: availabilityState;
  review: ReviewState;
};

const studentPersistConfig = {
  key: 'student',
  storage,
  whitelist: [],
};

const appReducer = combineReducers({
  user: persistReducer(userPersistConfig, user),
  avaliablity: persistReducer(avaliabilityPersistConfig, avaliablity),
  review: persistReducer(reviewPersistConfig, review),
  student: persistReducer(studentPersistConfig, student),
});

const rootReducer = (state: any, action: any) => {
  if (action.type === RESET_USER) {
    // for all keys defined in your persistConfig(s)
    storage.removeItem('persist:user');
    // storage.removeItem('persist:otherKey')

    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
