import React from 'react';
import {LoadingOutlined} from '@ant-design/icons';
import {Spin, Typography} from 'antd';
import './components.css';
type LoadingType = {
  title?: string;
  text?: string;
};

export const Loading = ({title = 'Loading', text}: LoadingType) => {
  const antIcon = (
    <LoadingOutlined
      style={{fontSize: 24}}
      spin
      onPointerEnterCapture={undefined}
      onPointerLeaveCapture={undefined}
    />
  );
  const {Text} = Typography;
  return (
    <div className="loader_page">
      <div className="loader_box">
        <Spin indicator={antIcon} style={{marginTop: '50px'}} />
        <Text className="" style={{textAlign: 'center'}}>
          {title}
        </Text>
        <Text
          style={{margin: 'auto', paddingRight: '20px', paddingLeft: '20px'}}
          className="loader_desc">
          {text}
        </Text>
      </div>
    </div>
  );
};
