import {generateClient, GraphQLResult} from 'aws-amplify/api';

import {IHomeworkRepository} from './interfaces/IHomeworkRepository';
import {createHomework} from '../../graphql/mutations';
const client = generateClient();
export class HomeworkRepository implements IHomeworkRepository {
  async createHomework(homework: any): Promise<any> {
    try {
      const createHomeworkResponse: GraphQLResult<any> = await client.graphql({
        query: createHomework,
        variables: {
          input: homework,
        },
      });

      return createHomeworkResponse.data?.createHomework.id;
    } catch (error) {
      console.log(' HomeworkRepository Error: ', error);
      throw new Error(error);
    }
  }
}
