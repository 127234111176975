import {generateClient, GraphQLResult} from 'aws-amplify/api';
import {
  signIn as signInAmplify,
  signOut as signOutAmplify,
  signUp as signUpAmplify,
  SignUpOutput,
  SignInOutput,
} from 'aws-amplify/auth';
import {customMe} from '../customQueries/user';
import {validatePartnerAuthUser} from '../../graphql/mutations';
import {AuthenticatorResponse} from '../../graphql/API';
import {
  getPartnerAuthToken,
  getPartnerCognitoId,
} from '../../redux/actions/user';
const client = generateClient();
export interface IAuthRepository {
  signIn(userName: string, password: string): Promise<SignInOutput | any>;
  signOut(): Promise<any>;
  signUp(email: string, password: string): Promise<SignUpOutput | Error>;
  validatePartnerAuth(
    partnerAppName: string,
    teacherId: string,
  ): Promise<AuthenticatorResponse>;

  getCurrentUser(): Promise<any>;
}

export class AuthRepository implements IAuthRepository {
  async getCurrentUser(): Promise<any> {
    if (getPartnerCognitoId()) {
      let res: GraphQLResult<any>;
      res = await client.graphql(
        {
          query: customMe,
          variables: {
            cognitoUserId: getPartnerCognitoId(),
          },
          authToken: getPartnerAuthToken(),
        },
        {
          identity: getPartnerCognitoId(),
        },
      );
      //@ts-ignore
      return res.data.me;
    } else {
      const res = await client.graphql({
        query: customMe,
        variables: {},
        authToken: getPartnerAuthToken(),
      });

      //@ts-ignore
      return res.data.me;
    }
  }

  async signIn(
    userName: string,
    password: string,
  ): Promise<SignInOutput | any> {
    return await signInAmplify({
      username: userName,
      password: password,
      options: {
        typeLogin: 'Schools',
      },
    });
  }

  async validatePartnerAuth(
    partnerAppName: string,
    teacherId: string,
  ): Promise<AuthenticatorResponse> {
    let token: GraphQLResult<any>;
    token = await client.graphql({
      query: validatePartnerAuthUser,
      variables: {
        input: {
          partnerId: teacherId,
          partnerAppName,
        },
      },
      authToken: 'partner_auth-' + partnerAppName,
    });
    return token.data.validatePartnerAuthUser;
  }

  async signOut(): Promise<any> {
    return await signOutAmplify();
  }

  async signUp(
    userName: string,
    password: string,
  ): Promise<SignUpOutput | Error> {
    return await signUpAmplify({
      username: userName,
      password: password,
    });
  }
}
