import {Progress, Typography} from 'antd';
import AudioMediaPlayer from './AudioMediaPlayer';
import {useEffect, useState} from 'react';
import {AnswerService} from '../AnswerService';
import { getUrl } from 'aws-amplify/storage';
import {Answer} from '../../../graphql/API';

interface AnswerProps {
  answerId: string;
  currentPlayingId: string | null;
  index: number;
  stopAudioPlayback: boolean;
  isPlaying: boolean;
  onPlay: () => void;
}

const answerService = new AnswerService();

const AnswerComponent: React.FC<AnswerProps> = ({
  answerId,
  index,
  stopAudioPlayback,
  isPlaying,
  onPlay,
  currentPlayingId,
}) => {
  const {Text} = Typography;

  const [answer, setAnswer] = useState<Answer>();
  const [ansuri, setAnsUri] = useState<string>('');

  useEffect(() => {
    async function fetchData() {
      const answerRes = await answerService.getAnswer(answerId);
      console.log('answerRes', answerRes);
      if (!answerRes?.audioUri) {
        alert('Error in your audio');
      }
      console.log('uri->>>>>>11111');
      let uri;
      try {
        uri = await getUrl(answerRes?.audioUri);
      } catch (error) {
        console.log('uri->>>>>>>>-11', error);
      }
      console.log('uri->>33333333', uri);
      setAnsUri(uri);
      setAnswer(answerRes);
    }
    fetchData();
  }, [answerId]);
  console.log('answer?.audioUri', answer?.audioUri);
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginTop: 10,
        flex: 1,
        justifyContent: 'space-between',
      }}>
      <AudioMediaPlayer
        title={`Answer ${index + 1}`}
        stopPlayback={stopAudioPlayback}
        src={ansuri}
        isPlaying={isPlaying}
        onPlay={onPlay}
        isAudio={answer?.audioUri === 'undefined' ? false : true}
        answerId={answerId}
        currentPlayingId={currentPlayingId}
      />
      <div>
        <Progress
          size={30}
          format={() => <div />}
          type="circle"
          percent={Number(answer?.score.toFixed(2))}
          strokeWidth={5}
          strokeColor={{'0%': '#F2406A', '100%': '#FFD252'}}
        />
        <Text
          style={{
            fontFamily: 'Arial Rounded MT Bold',
            fontSize: 18,
            marginLeft: 5,
          }}>
          {answer?.score?.toFixed(2) ?? 0}%
        </Text>
      </div>
    </div>
  );
};

export default AnswerComponent;
