import {Student} from '../../graphql/API';
import {SET_SELECTED_STUDENT} from '../actions/types';

export type StudentState = {
  selectedStudent: Student | null;
};

export const initialState: StudentState = {
  selectedStudent: null,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case SET_SELECTED_STUDENT:
      return {
        ...state,
        selectedStudent: action.payload,
      };
    default:
      return state;
  }
};
