export const getGradeByScore = (score: number) => {
  const gradeThresholds = [
    { min: 90, grade: 'A+' },
    { min: 85, grade: 'A' },
    { min: 80, grade: 'A-' },
    { min: 75, grade: 'B+' },
    { min: 70, grade: 'B' },
    { min: 65, grade: 'C+' },
    { min: 60, grade: 'C' },
    { min: 55, grade: 'D+' },
    { min: 50, grade: 'D' },
    { min: 40, grade: 'E' },
  ];
  for (const { min, grade } of gradeThresholds) {
    if (score >= min) {
      return grade;
  }
  return 'F';
};
}
  
  export const getGradeColor = (score: number) => {
    const colors = [
      '#C3E56E', // A+
      '#FFD252', // A
      '#40B2F2', // A-
      '#EF426B', // B+
      '#F772FA', // B
      '#D7D7D7', // C+
      '#B4B4B4', // C
      '#808080', // D+
      '#2F2F2F', // D
      '#D9D9D9', // E and F
    ];
    return colors[Math.floor(score / 10) - 4] || colors[9];
  };
