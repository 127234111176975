import {generateClient, GraphQLResult} from 'aws-amplify/api';

import {IQuestionsRepository} from './interfaces/IQuestionsRepository';
import {createQuestion} from '../customMutations/QuestionsMutations';
const client = generateClient();
export class QuestionsRepository implements IQuestionsRepository {
  async createQuestion(question: any): Promise<any> {
    try {
      const createQuestionResponse: GraphQLResult<any> = await client.graphql({
        query: createQuestion,
        variables: {
          input: question,
        },
      });
      return createQuestionResponse.data?.createQuestion.id;
    } catch (error) {
      console.log('create question Error: ', error);
      throw new Error(error);
    }
  }
}
