import {generateClient,GraphQLResult} from "aws-amplify/api";
import {getPartnerAuthToken} from '../../redux/actions/user';
import {getAnswer} from './CustomQueries';
import {IAnswerRepository} from './IAnswerRepository';

const client = generateClient();
export class AnswerRepository implements IAnswerRepository {
  constructor() {}

  async getAnswer(id: string): Promise<any> {
    let response: GraphQLResult<any>;
     response = await client.graphql({
      query: getAnswer,
      variables: {
        id,
      },
      authToken: getPartnerAuthToken()
    });

    console.log('response->getAnswer', response);
    //@ts-ignore
    return response.data.answer;
  }
}
