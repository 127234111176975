/** User */
export const GET_USER = 'GET_USER';
export const NO_USER_FOUND = 'NO_USER_FOUND';
export const RESET_USER = 'RESET_USER';
export const USER_IS_LOADING = 'USER_IS_LOADING';
export const SET_CONVERSATIONS = 'SET_CONVERSATIONS';
export const IS_LOADING_MESSAGE = 'IS_LOADING_MESSAGE';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN__WITH_PARTNER_SUCCESS = 'SIGN_IN__WITH_PARTNER_SUCCESS';
export const IS_LOADING_REQUEST_LOGIN = 'IS_LOADING_REQUEST_LOGIN';
// export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SET_SUBSCRIBED_ON_CONVERSATION = 'SET_SUBSCRIBED_ON_CONVERSATION';

/** appState */
export const IS_LOADING_REQUEST = 'IS_LOADING_REQUEST';

export const SET_AVALAIBILITIES_LIST = 'SET_AVALAIBILITIES_LIST';

/** review */
export const SET_REVIEW_QUESTIONS = 'SET_REVIEW_QUESTIONS';
export const SET_PERVIOUS_SESSION = 'SET_PERVIOUS_SESSION';

/**Student */
export const SET_SELECTED_STUDENT = 'SET_SELECTED_STUDENT';
