import {SET_SELECTED_STUDENT} from './types';

import {Student} from '../../graphql/API';

export const setSelectedStudent =
  (student: Student) => async (dispatch: any) => {
    try {
      dispatch({type: SET_SELECTED_STUDENT, payload: student});
    } catch (error) {
      console.log('SET_SELECTED_STUDENT error: ', error);
    }
  };
