import React, {useEffect, useState, useCallback, useMemo} from 'react';
import {Image, Table, Tabs, Typography, notification} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {setSelectedStudent} from '../../../redux/actions/student';
import {
  Classschool,
  MonthlyReport,
  RateStatus,
  School,
  Statistic,
  Student as StudentType,
} from '../../../graphql/API';
import {reducersState} from '../../../redux/reducers';
import {getGradeByScore, getGradeColor} from '../../../constants';
import Boy from '../../../assets/icons/Boy02.svg';
import RedDownCircle from '../../../assets/icons/redDownCircle.svg';
import GreenUpCircle from '../../../assets/icons/greenUpCircle.svg';
import {Loading} from '../../../components';
import {StatisticRepository} from '../StatisticRepository';
import {ReportHeader} from '../ReportHeader';
import moment from 'moment';

type LocationState = {
  classId: string;
  schoolId: string;
  school: School;
  selectedClass: Classschool;
  report: Statistic;
};
interface StudentRecord extends StudentType {
  key: string;
  arr: number;
  gradeColor: string;
  grade: string;
  statistic: StudentType['statistic'];
}

const rowStyles = {
  backgroundColor: '#F2406A',
  width: '40px',
  height: '40px',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
  fontFamily: 'Arial Rounded MT Bold',
};

const textStyle = {
  display: 'block',
  fontFamily: 'Arial Rounded MT Bold',
  color: '#000000',
  fontSize: 18,
};

const StudentsArrangementPage: React.FC = () => {
  const dispatch = useDispatch();
  const statisticRepository = new StatisticRepository();
  const history = useHistory();
  const location = useLocation<LocationState>();
  const classId = location.state?.classId;
  const schoolId = location.state?.schoolId;
  const school = location.state?.school;
  const selectedClass = location.state?.selectedClass;
  const report = location.state?.report;
  const {Text} = Typography;
  const [selectedClassId, setSelectedClassId] = useState<string>(classId);

  const {currentUser} = useSelector((state: reducersState) => state.user);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [sortedStudents, setSortedStudents] = useState<StudentType[]>([]);
  const isUpArrow = false;

  useEffect(() => {
    if (selectedClassId) {
      setIsLoading(true);

      statisticRepository
        .getStudentsBySchoolId(schoolId, selectedClassId)
        .then((student) => {
          const studentsSorted = [...student.items].sort(
            (a, b) =>
              (b.statistic?.averageScore ?? 0) -
              (a.statistic?.averageScore ?? 0),
          );
          setSortedStudents(studentsSorted);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [selectedClassId]);

  const dataSource = useMemo(
    () =>
      sortedStudents.map((student, index) => {
        const stdCurrentMonth = student.statistic?.monthlyReports.find(
          (monthReport: MonthlyReport) =>
            moment(monthReport?.month).format('MMMM-YYYY') ===
            moment().format('MMMM-YYYY'),
        );
        const gradeColor = getGradeColor(student.statistic?.averageScore ?? 0);
        const grade = getGradeByScore(student.statistic?.averageScore ?? 0);
        return {
          key: student.id,
          arr: index + 1,
          name: student.name,
          totalPerformance: student.statistic?.averageScore ?? 0,
          monthPerformance: {
            average: stdCurrentMonth?.average ?? 0,
            performanceRate: stdCurrentMonth?.performanceRate,
          },
          monthAbsence: {
            absence: stdCurrentMonth?.absenceDays ?? 0,
            absenceRate: stdCurrentMonth?.absenceRate,
          },
          monthDone: stdCurrentMonth?.attempts ?? 0,
          gradeColor,
          grade,
          statistic: student.statistic,
        };
      }),
    [sortedStudents],
  );
  const handleTabChange = (key: string) => {
    const selected = school.classesschool.find(
      (cls: Classschool) => cls.id === key,
    );
    setSelectedClassId(selected.id);
  };

  const columns = useMemo(
    () => [
      {
        title: 'ARR.',
        dataIndex: 'arr',
        key: 'arr',
        render: (arr: number) => <div style={rowStyles}>{arr}</div>,
        width: 100,
      },
      {
        title: 'STUDENT NAME',
        dataIndex: 'name',
        key: 'name',
        render: (name: string) => (
          <div
            style={{
              display: 'flex',
              gridAutoFlow: 'column',
              alignItems: 'center',
            }}>
            <Image
              width={80}
              preview={false}
              src={Boy}
              style={{display: 'inline-block'}}
              wrapperStyle={{verticalAlign: 'top', padding: 10}}
            />
            <div style={{maxWidth: 200}}>
              <Typography.Text style={textStyle}>{name}</Typography.Text>
            </div>
          </div>
        ),
        width: 300,
      },
      {
        title: 'TOTAL PERFORMANCE',
        dataIndex: 'totalPerformance',
        key: 'grade',
        render: (text: any, record: {gradeColor: string; grade: string}) => (
          <div style={{display: 'flex', alignItems: 'center'}}>
            <div
              className="gradeContainer"
              style={{
                backgroundColor: record.gradeColor,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Typography.Text
                style={{
                  color: 'white',
                  fontSize: '23px',
                  fontFamily: 'Arial Rounded MT Bold',
                }}>
                {record.grade}
              </Typography.Text>
            </div>
            <Typography.Text style={textStyle}>{text} %</Typography.Text>
          </div>
        ),
        width: 200,
      },
      {
        title: 'THIS MONTH PERFORMANCE',
        dataIndex: 'monthPerformance',
        key: 'monthPerformance',
        render: (monthPerformance: any) => (
          <div
            style={{
              display: 'flex',
              gridAutoFlow: 'column',
              alignItems: 'center',
            }}>
            <Typography.Text style={textStyle}>
              {Math.round(monthPerformance?.average)}%
            </Typography.Text>
            {monthPerformance?.performanceRate && (
              <Image
                width={60}
                preview={false}
                src={
                  monthPerformance?.performanceRate === RateStatus.HIGH
                    ? GreenUpCircle
                    : RedDownCircle
                }
                style={{display: 'inline-block'}}
                wrapperStyle={{verticalAlign: 'top', padding: 10}}
              />
            )}
          </div>
        ),
        width: 150,
      },
      {
        title: 'THIS MONTH ABSENCE DAYS',
        dataIndex: 'monthAbsence',
        key: 'monthAbsence',
        render: (monthAbsence: any) => (
          <div
            style={{
              display: 'flex',
              gridAutoFlow: 'column',
              alignItems: 'center',
            }}>
            <Typography.Text style={textStyle}>
              {monthAbsence?.absence}
            </Typography.Text>
            {monthAbsence?.absenceRate && (
              <Image
                width={60}
                preview={false}
                src={
                  monthAbsence?.absenceRate === RateStatus.HIGH
                    ? GreenUpCircle
                    : RedDownCircle
                }
                style={{display: 'inline-block'}}
                wrapperStyle={{verticalAlign: 'top', padding: 10}}
              />
            )}
          </div>
        ),
        width: 150,
      },
      {
        title: 'THIS MONTH DONE EXERCISES',
        dataIndex: 'monthDone',
        key: 'monthDone',
        render: (doneExercises: number) => (
          <div
            style={{
              display: 'flex',
              gridAutoFlow: 'column',
              alignItems: 'center',
            }}>
            <Typography.Text style={textStyle}>{doneExercises}</Typography.Text>
            {/* <Image
              width={60}
              preview={false}
              src={isUpArrow ? GreenUpCircle : RedDownCircle}
              style={{display: 'inline-block'}}
              wrapperStyle={{verticalAlign: 'top', padding: 10}}
            /> */}
          </div>
        ),
        width: 150,
      },
    ],
    [],
  );

  const onRow = useCallback(
    (record: StudentRecord) => ({
      onClick: () => {
        console.log('record', record);

        dispatch(setSelectedStudent(record));
        if (record?.statistic) {
          history.push(`/home/student/${record.key}`);
        } else {
          notification.warning({
            placement: 'top',
            duration: 3,
            message: 'No Report for this student yet',
          });
        }
      },
    }),
    [dispatch, history],
  );

  const tabItems = school.classesschool.map((cls: Classschool) => ({
    label: (
      <Text
        style={{
          display: 'block',
          fontFamily: 'Arial Rounded MT Bold',
          color: '#999999',
          fontSize: 16,
        }}>
        Class {cls?.name}
      </Text>
    ),

    key: cls.id,
    children: (
      <div style={{margin: 30}}>
        <ReportHeader
          school={school}
          selectedClass={selectedClass}
          report={report}
        />

        {!isLoading && (
          <Table
            style={{marginTop: 30}}
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            scroll={{y: 600}}
            onRow={onRow}
          />
        )}
        {isLoading && (
          <Loading title={'Loading students data. it may take a minute.'} />
        )}
      </div>
    ),
  }));

  return (
    <div>
      <Tabs
        defaultActiveKey={selectedClass.id}
        onChange={handleTabChange}
        items={tabItems}
        centered
      />
    </div>
  );
};

export default StudentsArrangementPage;
