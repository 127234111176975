import React from 'react';
import {Spin} from 'antd';
import {reducersState} from '../../redux/reducers';
import {useDispatch, useSelector} from 'react-redux';
import {logIn} from '../../redux/actions/user';
import {Link} from 'react-router-dom';

const appStyle = {
  height: '850px',
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
};

const formStyle = {
  margin: 'auto',
  padding: '10px',
  border: '1px solid #c9c9c9',
  borderRadius: '5px',
  background: '#f5f5f5',
  width: '320px',
  display: 'block',
  marginTop: 100,
  alignSelf: 'center',
};

const labelStyle = {
  margin: '10px 0 5px 0',
  fontFamily: 'Arial, Helvetica, sans-serif',
  fontSize: '15px',
};

const inputStyle: any = {
  margin: '5px 0 10px 0',
  padding: '5px',
  border: '1px solid #bfbfbf',
  borderRadius: '3px',
  boxSizing: 'border-box',
  width: '100%',
};

const submitStyle: any = {
  margin: '10px 0 0 0',
  padding: '7px 10px',
  border: '1px solid #efffff',
  borderRadius: '3px',
  background: '#3085d6',
  width: '100%',
  fontSize: '15px',
  color: 'white',
  display: 'block',
};

const Field = React.forwardRef(({label, type}: any, ref: any) => {
  return (
    <div>
      <label style={labelStyle}>{label}</label>
      <input ref={ref} type={type} style={inputStyle} />
    </div>
  );
});

const Form = ({onSubmit}) => {
  const usernameRef = React.useRef<any>();
  const passwordRef = React.useRef<any>();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (usernameRef?.current?.value && passwordRef?.current?.value) {
      const data = {
        username: usernameRef?.current!.value,
        password: passwordRef?.current?.value,
      };
      onSubmit(data);
    }
  };
  return (
    <form style={formStyle} onSubmit={handleSubmit}>
      <Field ref={usernameRef} label="Email:" type="text" />
      <Field ref={passwordRef} label="Password:" type="password" />
      <div>
        <button style={submitStyle} type="submit">
          Submit
        </button>
      </div>
    </form>
  );
};

const SignUp = () => {
  const isLoading: boolean = useSelector(
    (state: reducersState) => state.user.isLoadingRequest,
  );
  const dispatch = useDispatch();
  const handleSubmit = async (data) => {
    dispatch(logIn(data.username, data.password));
  };
  // http://localhost:3000/?partner:CLASSERA?token:xxxxx
  return (
    <div style={appStyle}>
      <div style={{right: 0, position: 'absolute'}}>
        {/* <Spin spinning={isLoading}> */}
        <Link
          style={submitStyle}
          to={{
            pathname:
              window.location.hostname.includes('staging') ||
              window.location.hostname.includes('localhost')
                ? 'https://me-stg.classera.com/AuthWithClassera?partnerCode=PIKADO&typeLogin=teacher'
                : 'https://me.classera.com/AuthWithClassera?partnerCode=PikaDo&typeLogin=teacher',
          }}
          target="_parent">
          Sign In with Classera
        </Link>
        {/* </Spin> */}
      </div>
      <Spin spinning={isLoading}>
        <Form onSubmit={handleSubmit} />
      </Spin>
    </div>
  );
};

export default SignUp;
