import {AnswerRepository} from './AnswerRepository';
import {IAnswerService} from './IAnswerService';

export class AnswerService implements IAnswerService {
  answerRepository: AnswerRepository;

  constructor() {
    this.answerRepository = new AnswerRepository();
  }

  async getAnswer(id: string): Promise<any> {
    return await this.answerRepository.getAnswer(id);
  }
}
