import {IStatisticRepository} from './IStatisticRepository';
import {generateClient,GraphQLResult} from "aws-amplify/api";
import {generateClassFullReport} from '../../graphql/mutations';
import {fullReport, minimalStudent, studentsTable} from './CustomQueries';
import {getPartnerAuthToken} from '../../redux/actions/user';

const client = generateClient();
export class StatisticRepository implements IStatisticRepository {
  constructor() {}

  async generateClassFullReport(classschoolId: string): Promise<any> {
    let response: GraphQLResult<any>;
    response = await client.graphql({
      query: generateClassFullReport,
      variables: {
        classschoolId: classschoolId,
      },
      authToken: getPartnerAuthToken()
    })
    console.log('response from generateClassFullReport->', response);
    return response.data.generateClassFullReport;
  }

  async getFullReport(statisticId: string): Promise<any> {
    let response: GraphQLResult<any>;
    response = await client.graphql({
      query: fullReport,
      variables: {
        id: statisticId,
      },
      authToken: getPartnerAuthToken()
    });
    console.log('response->', response);
    return response.data.statistic;
  }

  async getStudentById(studentId: string): Promise<any> {
    let response: GraphQLResult<any>;
    response = await client.graphql({
      query: minimalStudent,
      variables: {
        id: studentId,
      },
      authToken: getPartnerAuthToken()
    });
    console.log('response->', response);
    return response.data.student;
  }
  async getStudentsBySchoolId(
    schoolId: string,
    classId: string,
  ): Promise<any> {    
    let response: GraphQLResult<any>;
    response = await client.graphql({
      query: studentsTable,
      variables: {
        filter:{
            schoolId: {
              eq: schoolId
            },
            classschoolId: {
              eq: classId
            }
          },
      },
      authToken: getPartnerAuthToken()
    });
    console.log('response studentsss->', response);
    return response.data.students;
  }
}
