import React from 'react';
import {Line, G2, LineConfig} from '@ant-design/plots';

import {each, findIndex} from '@antv/util';

export type DemoLineType = {
  data: Array<{month: string; score: number}>;
};
export const DemoLine = ({data}: DemoLineType) => {
  const {InteractionAction, registerInteraction, registerAction} = G2;

  G2.registerShape('point', 'custom-point', {
    draw(cfg, container) {
      const point = {
        x: cfg.x,
        y: cfg.y,
      };
      const group = container.addGroup();
      group.addShape('circle', {
        name: 'outer-point',
        attrs: {
          //@ts-ignore
          x: point.x,
          //@ts-ignore
          y: point.y,
          fill: '#40B2F2',
          opacity: 0.5,
          r: 4,
        },
      });
      group.addShape('circle', {
        name: 'inner-point',
        attrs: {
          //@ts-ignore
          x: point.x,
          //@ts-ignore
          y: point.y,
          fill: '#40B2F2',
          opacity: 1,
          r: 2,
        },
      });
      return group;
    },
  });

  class CustomMarkerAction extends InteractionAction {
    active() {
      const view = this.getView();
      const evt = this.context.event;

      if (evt.data) {
        // items: 数组对象，当前 tooltip 显示的每条内容
        const {items} = evt.data;
        const pointGeometries = view.geometries.filter(
          (geom) => geom.type === 'point',
        );
        each(pointGeometries, (pointGeometry) => {
          each(pointGeometry.elements, (pointElement, idx) => {
            const active =
              findIndex(
                items,
                (item: any) => item.data === pointElement.data,
              ) !== -1;
            const [point0, point1] = pointElement.shape.getChildren();

            if (active) {
              // outer-circle
              point0.animate(
                {
                  r: 100,
                  opacity: 0.2,
                },
                {
                  duration: 1800,
                  easing: 'easeLinear',
                  repeat: true,
                },
              ); // inner-circle

              point1.animate(
                {
                  r: 6,
                  opacity: 0.4,
                },
                {
                  duration: 800,
                  easing: 'easeLinear',
                  repeat: true,
                },
              );
            } else {
              this.resetElementState(pointElement);
            }
          });
        });
      }
    }

    reset() {
      const view = this.getView();
      const points = view.geometries.filter((geom) => geom.type === 'point');
      each(points, (point) => {
        each(point.elements, (pointElement) => {
          this.resetElementState(pointElement);
        });
      });
    }

    resetElementState(element: any) {
      const [point0, point1] = element.shape.getChildren();
      point0.stopAnimate();
      point1.stopAnimate();
      const {r, opacity} = point0.get('attrs');
      point0.attr({
        r,
        opacity,
      });
      const {r: r1, opacity: opacity1} = point1.get('attrs');
      point1.attr({
        r: r1,
        opacity: opacity1,
      });
    }

    getView() {
      return this.context.view;
    }
  }

  registerAction('custom-marker-action', CustomMarkerAction);
  registerInteraction('custom-marker-interaction', {
    start: [
      {
        trigger: 'tooltip:show',
        action: 'custom-marker-action:active',
      },
    ],
    end: [
      {
        trigger: 'tooltip:hide',
        action: 'custom-marker-action:reset',
      },
    ],
  });
  console.log('data config->', data);
  const config: LineConfig = {
    data,
    xField: 'Month',
    yField: 'Score',
    yAxis: {
      field: 'joho',
    },
    smooth: true,
    color: '#FFD252',

    lineStyle: {lineWidth: 4},
    point: {
      size: 5,
      shape: 'custom-point',
      style: {
        fill: '40B2F2',
        stroke: '#40B2F2',
        width: 5,
      },
    },
    tooltip: {
      showMarkers: false,
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: '#40B2F2',
          fill: 'red',
        },
      },
    },
    interactions: [
      {
        type: 'custom-marker-interaction',
      },
    ],
  };
  return <Line {...config} />;
};
