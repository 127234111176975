import {
  GET_USER,
  NO_USER_FOUND,
  IS_LOADING_REQUEST,
  SIGN_IN_SUCCESS,
  SET_CONVERSATIONS,
  SET_SUBSCRIBED_ON_CONVERSATION,
  SIGN_IN__WITH_PARTNER_SUCCESS,
  IS_LOADING_REQUEST_LOGIN,
} from '../actions/types';
import { SignInOutput } from 'aws-amplify/auth';
import {PikaDoPartners, User, Userconversation} from '../../graphql/API';

export type UserState = {
  user: SignInOutput | null;
  currentUser: User | null;
  partnerUser: PartnerUser | null;
  isLoggedIn: boolean;
  isLoadingRequest: boolean;

  error: null | any;

  conversations: Array<Userconversation>;
  subscribedConversationIds: Array<string>;
};

export type PartnerUser = {
  partnerAppName: PikaDoPartners | null;
  sharedTeacherId: string | null;
  isAuthenticated: boolean;
  cognitoUserPartnerId: string;
  token?: string | null;
};

export const initialState: UserState = {
  user: null,
  isLoggedIn: false,
  currentUser: null,
  partnerUser: null,
  isLoadingRequest: false,
  error: null,
  conversations: [],
  subscribedConversationIds: [],
};

const user = (state = initialState, action: any) => {
  switch (action.type) {
    case SIGN_IN_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isLoggedIn: true,
      };

    case GET_USER:
      return {
        ...state,
        currentUser: action.payload,
      };

    case NO_USER_FOUND:
      return {
        ...state,
        user: null,
        error: null,
        isLoggedIn: false,
        partnerUser: null,
      };

    case IS_LOADING_REQUEST: {
      return {
        ...state,
        isLoadingRequest: action.payload.isLoading,
        error: action.payload.error,
      };
    }

    case SET_CONVERSATIONS: {
      return {
        ...state,
        conversations: action.payload,
      };
    }

    case SET_SUBSCRIBED_ON_CONVERSATION: {
      return {
        ...state,
        subscribedConversationIds: action.payload,
      };
    }

    case IS_LOADING_REQUEST_LOGIN:
      return {
        ...state,
        isLoadingRequest: action?.payload?.isLoading,
        error: action?.payload?.error || null,
      };

    case SIGN_IN__WITH_PARTNER_SUCCESS:
      return {
        ...state,
        partnerUser: action.payload,
        isLoading: false,
        isLoggedIn: true,
        error: null,
      };

    default:
      return state;
  }
};

export default user;
