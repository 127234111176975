export const customMe = /* GraphQL */ `
  query Me($cognitoUserId: String) {
    me(cognitoUserId: $cognitoUserId) {
      id
      createdAt
      updatedAt
      whatsappNumber
      cognitoUserId
      country
      state
      timeZone
      school {
        id
        createdAt
        promo {
          id
          type
          thumbnailUri
          fileUri
        }

        updatedAt
        classesschool {
          name
          statistic {
            id
            averageScore
          }
          level {
            id
            number
          }
          id
          students {
            id
            age
            user {
              id
              country
            }
            statistic {
              id
              averageScore
            }
            name
          }
        }
        nameArabic
        name
      }
      teacher {
        id
        name
        profilePicture {
            fileUri
            thumbnailUri
          }
        classesschool {
          name
          statistic {
            id
            averageScore
          }
          level {
            id
            number
          }
          id
          students {
            id
            statistic {
              id
              averageScore
            }
            name
          }
        }
        school {
          id
          createdAt
          promo {
            id
            type
            thumbnailUri
            fileUri
          }
          students {
            id
          }
          updatedAt
          classesschool {
            name
            statistic {
              id
              averageScore
            }
            level {
              id
              number
            }
            id
            students {
              id
              statistic {
                id
                averageScore
              }
              name
            }
          }
          nameArabic
          name
        }
      }
    }
  }
`;
